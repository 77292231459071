export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBk6aLvV8zQt0Ql4tz8_bZm5wshbG5ihBA',
    authDomain: 'lc-website-a56e8.firebaseapp.com',
    projectId: 'lc-website-a56e8',
    storageBucket: 'lc-website-a56e8.appspot.com',
    messagingSenderId: '794349791308',
    appId: '1:794349791308:web:82a1647eebc31ff24a6ce9',
    measurementId: 'G-6CSTYFJ3MQ'
};

/* export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
}; */

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/default';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'sv', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
    update: false,
    edit: false
};

export default config;
