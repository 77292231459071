import firebase from 'firebase/compat/app';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

export const db = firebase.firestore();

export const dbf = async ({ collectionId, docu }) => {
    const docRef = docu ? doc(db, collectionId, docu) : collection(db, collectionId);
    const docSnap = docu ? await getDoc(docRef) : await getDocs(docRef);
    let finalData = [];
    if (docu) {
        if (docSnap.exists()) {
            finalData = docSnap.data();
        } else {
            finalData = null;
        }
    } else if (collectionId && !docu) {
        docSnap.forEach((doc) => {
            const obj = {
                id: doc.id,
                data: doc.data()
            };
            finalData.push(obj);
        });
    }
    return finalData;
};
