// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="35"
            height="35"
            x="0"
            y="0"
            version="1"
            viewBox="0 0 92 92"
            xmlSpace="preserve"
        >
            <g transform="matrix(1.33333 0 0 -1.33333 0 166.553)">
                <g>
                    <defs>
                        <path id="SVGID_1_" d="M-154.4 -6.2H223.49999999999997V160.4H-154.4z" />
                    </defs>
                    <clipPath id="SVGID_00000003091058705713495800000006532198014041149084_">
                        <use overflow="visible" xlinkHref="#SVGID_1_" />
                    </clipPath>
                    <g clipPath="url(#SVGID_00000003091058705713495800000006532198014041149084_)">
                        <g transform="translate(115.651 41.232)">
                            <path
                                fill="#FFF"
                                d="M-107.2 14.6c-4.7 0-8.5 3.8-8.5 8.5v52.2c0 4.7 3.8 8.5 8.5 8.5H-55c4.7 0 8.5-3.8 8.5-8.5V23.1c0-4.7-3.8-8.5-8.5-8.5h-52.2z"
                            />
                        </g>
                        <g transform="translate(174.922 49.746)">
                            <path
                                fill="#D8292F"
                                d="M-107.2 14.6c0-3.9-3.2-7.1-7.1-7.1h-52.2c-3.9 0-7.1 3.2-7.1 7.1v52.2c0 3.9 3.2 7.1 7.1 7.1h52.2c3.9 0 7.1-3.2 7.1-7.1V14.6z"
                            />
                        </g>
                        <g transform="translate(164.926 80.91)">
                            <path fill="#FFF" d="M-107.2 14.6h-18.1v18.1h-10.2V14.6h-18.1V4.4h18.1v-18.1h10.2V4.4h18.1v10.2z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Logo;
